.servicesCard-container {
  width: 100%;
  margin-top: 120px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
}
.servicesCard-container div {
  max-width: 397px;
  /* height: 280px; */
  border-radius: 20px;
  padding: 12px;
}
.title-card {
  background-color: #060709;
  display: grid;
  place-content: center;
  align-items: flex-start;
}
.title-card h5 {
  color: var(--light-grey);
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}
.title-card h3 {
  color: #fff;
  font-size: 36px;
  font-weight: 400;
}
.service-card {
  border: 1px solid #262626;
}
.service-card h4 {
  font-size: 24px;
  color: #e5e5e5;
  padding: 15px 0 10px;
}
.service-card p {
  font-size: 18px;
  font-weight: 300;
  color: var(--light-grey);
  line-height: 32px;
}

@media only screen and (max-width: 1100px) {
  .service-card img {
    width: 30px;
    height: 30px;
  }
  .service-card h4 {
    font-size: 20px;
  }
  .service-card p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1036px){
  .title-card h3 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 831px) {
  .servicesCard-container {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 80px;
  }
}
@media only screen and (max-width: 580px) {
  .servicesCard-container {
    grid-template-columns: 1fr;
    /* margin-top: 80px; */
  }
  .title-card{
    height: 175px !important;
  }
  .servicesCard-container div {
    max-width: 100%;
  }
}
@media only screen and (max-width: 372px) { 
  .title-card h3{
    font-size: 24px;
  }
}
