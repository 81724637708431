.pagination{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: max-content;
    height: 150px;
    margin: 0 auto;
}
.pagination li{
    font-size: 14;
    font-weight: 400;
    padding: 10px;
    cursor: pointer;
    color: #fff;
    display: grid;
    place-content: center;


}
.posthidden{
    display: none !important;
}
.pagination li.active{
    background-color: #fff;
    color: #000 !important;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
/* .pagination p{
    margin-left: 1rem;
}
.pagination b,.pagination span{
    font-size: 14px;
    text-transform: capitalize;
}
.pagination b{
    color: var(--deep-blue);
    font-weight: 600;
} */