
.values-main-div {
    justify-content: space-evenly;
    align-items: start;
    
    background-color: #212121;
    border-radius: 8px;
    height: 60vh;
}

.images-cores{
    width: 66px;
}

.text{
    font-weight: 200;
    font-size: 12px;
    line-height: 16px;
    color: #858585;
}
.h5text{
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 24px;
}

.inner-section{
    padding: 54px 22px 25px;
}


@media only screen and (max-width: 850px){
    .values-main-div:nth-child(3){
        width: 100%;
        grid-column: span 2;
    }
    .values-main-div {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        
        background-color: #212121;
        border-radius: 8px;
        height: 50vh;
    }
  
}

@media only screen and (max-width: 650px){
    .values-main-div {
        height: 50vh;
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 460px){
    .inner-section{
        padding: 40px 22px 25px;
    }
    .images-cores{
        width: 40px;
    }
    .values-main-div {
        height: 55vh;
        font-size: 18px;
        justify-content: space-evenly;
    }
    .values-main-div:nth-child(3){
        grid-column: span 1;
    }

}

@media only screen and (max-width: 380px){
    .inner-section{
        padding: 40px 22px 25px;
    }
    .images-cores{
        width: 40px;
    }
    .values-main-div {
        height: 60vh;
        font-size: 18px;
        justify-content: space-evenly;
    }
    .values-main-div:nth-child(3){
        grid-column: span 1;
    }

}