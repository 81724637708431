:root{
  --text-anim-delay: 0s;
}

.hideText{
  transform: translateY(100%);
  display: block;
  opacity: 0.1;
  transition: all 0.6s var(--text-anim-delay) ease-in-out;
}
.showText{
  opacity: 1;
  transform: translateY(0%);
}
.portfolio-group{
  display: flex;
  column-gap: 0.75rem;
}
.custom-list-disc{
  width: 9px;
  height: 7px;
  background: white;
  border-radius: 50%;
}
.highlight{
  background: rgba(255, 255, 255, 0.874);
  /* color: rgba(0, 0, 0, 0.182); */
  border-radius: 50%;
  box-shadow: 0 0 20px white,0 0 30px rgb(184, 184, 184), 0 0 40px white;
}
.centerX{
  transform: translate(-50%,0%);
}
.centerY{
  transform: translate(0%,-50%);
}
.centerXY{
  left:-50%;
  top: 50%;
  transform: translate(50%,-50%);
}
.test-out:hover{
  box-shadow: 0 0 5px #4169E126,0 0 10px #4169E126, 0 0 20px #4169E126;
  transition: all 0.4s ease;
  /* color: #4169e118; */
}
.selected-responsive-nav{
  background: rgba(31, 31, 31, 0.556);
  padding-left: 15px;
  text-decoration: underline;
  line-height: 2.3em;
  border-radius: 4px;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
}
.learn-more li:nth-child(2) > div > p{
  --text-anim-delay: 0.1s;
}
.learn-more li:nth-child(3) > div > p{
  --text-anim-delay: 0.2s;
}
.learn-more li:nth-child(4) > div > p{
  --text-anim-delay: 0.3s;
}
/* .contactCard:hover img{
  animation: animatingIcon 3s linear infinite reverse;
}
@keyframes animatingIcon {
  0%{
      transform: translateY(-5px);
  }
  100%{
      transform: translateY(-0px);
  }
} */
.rot3d{
  -webkit-transform: rotateX(30);
  transform: rotateX(30);
  transform-origin: 580px 100;
  -webkit-transform-origin-y: 580px;
  -webkit-transform-origin-z: 100;
  animation: tor 3s ease infinite ;
}
.item .sub-menu{
  height: 0;
}
.item .drop-handler:checked ~ .sub-menu{
  height: 100%;
}
.item .drop-handler:checked ~ label svg{
  transform: rotate(90deg);
  transition: all 0.3s ease;
}
.item label svg{
  transform: rotate(0deg);
  transition: all 0.3s ease;
}
.force-width{
  width: 20rem !important;
}
.grey-back{
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%); 
  -o-filter: grayscale(100%); 
  /* filter: url(resources.svg#desaturate);  */
  filter: gray; 
  -webkit-filter: grayscale(1);
}
.service-background{
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23111111' stroke-width='2'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%234D4D4D'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.major-background{
  background-image: linear-gradient(360deg,transparent,transparent 90%,#4169e118), url(/public/images/hero_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.blur-class{
  box-shadow: inset 0 0 10px grey,inset 0 0 20px grey,inset 0 0 30px grey,0 0 10px grey;
}
.border-animation:nth-child(even){
  --clr: #4169E1
}
.border-animation{
  --clr: #ffa500
}
.border-animation{
  position: relative;
  z-index: 30;
  overflow: hidden;
}
.border-animation:hover{
  border: 2px solid #4169E1;
  /* transition: all .2s ease; */
}
.border-animation:hover::after{
  inset: 0;
  transition: all .1s ease;
  background-color: #4169E11A;
}
.tab-class{
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 4px 10px;
  cursor: pointer;
  user-select: none;
  color: grey;
}
.selected-tab{
  background: white;
  color: black;
  border-radius: 5px;
}
.border-animation:hover::before{
  width: 0;
  height: 0;
}
.border-animation::before{
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(var(--clr),transparent,transparent);
  width: 20em;
  z-index: 1;
  height: 20em;
  border-radius: 999px;
  pointer-events: none;
  animation: animate 2.5s linear infinite;
}
.border-animation::after{
  position: absolute;
  content:  " ";
  z-index: 1;
  pointer-events: none;
  inset: 3px;
  background: rgb(39, 39, 39);
}

@keyframes animate {
  0%{
      transform: translate(-30%,-30%);
  }
  25%{
      transform: translate(100%,-30%);
  }
  50%{
      transform: translate(100%,50%);
  }
  75%{
      transform: translate(-30%,50%);
  }
  100%{
      transform: translate(-30%,-30%);
  }
}
.responsivePadding{
  padding: clamp(1em,8vw,5em);
}
@keyframes tor {
  0%{
      -webkit-transform: rotateX(0);
  transform: rotateX(0);
  }
  100%{
      -webkit-transform: rotateX(30);
  transform: rotateX(30);
  }
}

@media only screen and (min-width: 568px) and (max-width: 780px) {
  .flex_control {
    /* Override the flex behavior to maintain stacking */
    flex-direction: column;
    align-items: center;
  }
}
