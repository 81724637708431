.homepage {
  max-width: 100%;
  height: auto;
  background: #000;
}

/* hero section styles */
.homepage_hero {
  max-width: 100%;
  min-height: 85vh;
  padding: 170px 60px 140px;
  text-align: center;
  place-content: center;
  background: linear-gradient(
      183deg,
      rgba(65, 105, 225, 0.15) -38.62%,
      rgba(65, 105, 225, 0) 97.56%
    ),
    top / cover no-repeat url(/public/images/ph-hero-bg.webp);
}

span.Typewriter__cursor {
  display: none;
}
.homepage {
  max-width: 100%;
  height: auto;
  background: #000;
}

/* hero section styles */
.homepage_hero {
  max-width: 100%;
  min-height: 85vh;
  padding: 170px 60px 140px;
  text-align: center;
  place-content: center;
  background: linear-gradient(
      183deg,
      rgba(65, 105, 225, 0.15) -38.62%,
      rgba(65, 105, 225, 0) 97.56%
    ),
    top / cover no-repeat url(/public/images/ph-hero-bg.webp);
}
.Typewriter{
  height: 180px !important;
  width: 85% !important;
  margin: 0 auto;
}

span.Typewriter__cursor {
  display: none;
}

.homepage_hero h1 {
  font-size: 55px;
  height: 100%;
}

.homepage_hero p {
  font-size: 26px;
  width: 800px !important;
  font-weight: 400;
  letter-spacing: 0.15;
}
/* home page main (with vertical slider component) styles */
.homepage #main-content {
  background: #060709;
  background: var(--ellipse-gradient);
  padding-top: 150px;
}

.homepage #main-content .text_col h2 {
  font-size: 60px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  width: 50%;
  margin: 0 auto;
  padding-bottom: 24px;
  color: #fff;
  text-align: center;
}
.homepage #main-content .text_col p {
  font-size: 18px;
  font-weight: 500;
  color: var(--light-grey);
  width: 45%;
  margin: 0 auto;
  padding-bottom: 26px;
  text-align: center;
}

#main-content section {
  background: linear-gradient(
      183deg,
      rgba(6, 7, 9, 0.418) -38.62%,
      rgba(6, 7, 9, 0.742) 97.56%
    ),
    center / cover no-repeat url(/public/images/grid_background.png);
  padding: 30px 80px 150px;
}

/* middle section styles */
.middle_section{
  background-color: var(--charcoal-black);
  width: 100%;
}
.middle_section .container_x {
  padding: 90px 0 !important;
  width: 100%;
  min-height: auto;
  background-color: var(--charcoal-black);
}

.middle_section .container_x {
  gap: 50px;
  padding: 70px 90px !important;
}
.middle_section .text_col {
  flex-basis: 55%;
}

.middle_section h2 {
  font-size: 60px;
  color: #fff;
  font-weight: 600;
  margin-bottom: -120px;
  margin-left: 90px;
}
.middle_section .text_col p, .middle_section .last_text {
  font-size: 18px;
  font-weight: 400;
  color: var(--light-grey);
  line-height: 28px;
  margin-bottom: 40px;
}
.middle_section .img_col {
  flex-basis: 45%;
}

.middle_section .img_col .img-wrap {
  width: 424px;
  height: 424px;
  margin: 0 auto;
  background: url("/public/images/home/about-img-lg-scr.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: var(--shadow);
  -webkit-box-shadow: var(--shadow);
  -moz-box-shadow: var(--shadow);
  transition: all 0.5s ease-in-out;
}

.middle_section .img_col .img-wrap:hover {
  box-shadow: none;
}

/* vision-mission section */
.vision-mission-wrapper {
  background: #060709;
  background: var(--ellipse-gradient);
  padding: 90px;
}
.section-img {
  width: 40%;
}
.vision-mission-text {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 84px;
}
.vision-mission-text .top,
.vision-mission-text .bottom {
  text-align: right;
}
.vision-mission-text h3 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
}
.vision-mission-text p {
  font-size: 18px;
  font-weight: 400;
}
/* Services */
#services-section{
  padding: 120px 90px 50px;
}
#services-section .text_col{
  text-align: center;
}
#services-section .text_col h2{
  font-size: 60px;
  font-weight: 600;
}
#services-section .text_col p {
  font-size: 18px;
  font-weight: 400;
  width: 85%;
  margin: 24px auto 0;
}





@media only screen and (max-width: 2480px) {
  .hero_gap {
    padding-top: 90px;
  }
  .inner_padding {
    padding-left: 60px;
    padding-right: 60px;
    font-size: 12px;
  }
  .Typewriter {
    height: 280px;
    width: 920px;
    font-size: 36px;
  }
}

@media only screen and (max-width: 1300px) {
  .hero_gap {
    padding-top: 70px !important;
  }
  .inner_padding {
    padding-left: 60px;
    padding-right: 60px;
    font-size: 12px;
  }
  /* .Typewriter {
    height: 240px;
  } */
  .homepage #main-content .text_col h2 {
    width: 70% !important;
  }
}

@media only screen and (max-width: 1240px) {
  .middle_section h2 {
    margin-left: 50px !important;
  }
  .middle_section .container_x {
    gap: 30px;
    padding: 70px 50px !important;
  }
  .vision-mission-wrapper {
    padding: 70px 50px;
  }
  #services-section{
    padding: 90px 50px 50px;
  }
  #main-content section {
    padding: 30px 50px 150px;
  }
  
}
@media only screen and (max-width: 1170px) {
  .middle_section .img_col .img-wrap {
    width: 400px;
    height: 400px;
  }
  .Typewriter{
    width: 90% !important;
  }
  
}
@media only screen and (max-width: 1100px) {
 
  .hero_gap {
    padding-top: 0px !important;
  }

  .Typewriter {
    height: 350px !important;
    width: 100% !important;
    text-align: center;
  }
  .homepage_hero h1 {
    font-size: 68px;
  }

  .middle_section .text_col {
    width: 70%;
  }
}

@media only screen and (max-width: 1085px) {
  .text_col h2 {
    width: 100%;
  }

  .homepage_hero p {
    font-size: 22px;
    width: 600px !important;
  }
  .middle_section,
  .middle_section .text_col {
    text-align: center;
  }
  .middle_section .flex_wrapper {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .middle_section .img_col {
    margin: 0 auto;
  }

  .middle_section .text_col {
    text-align: center;
    width: 100%;
  }

  .middle_section .text_col p {
    width: 100%;
  }
  .middle_section h2 {
    margin: 0 auto 35px !important;
  }
  .middle_section .img_col {
    flex-basis: 100%;
    width: 100%;
  }
  .middle_section .img_col .img-wrap {
    width: 80% !important;
    height: 312px;
    background: url("/public/images/home/about-img-md-scr.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .section-img {
    width: 40%;
  }
  .vision-mission-text {
    width: 60%;
    gap: 50px;
  }
    .middle_section{
    padding: 80px 0;
  }
  .middle_section .container_x {
    gap: 80px;
  }
  .middle_section h2 {
    /* font-size: 36px; */
    margin-left: 0px !important;
    margin-bottom: 0px !important;
  }
}

/* for tabs */

@media only screen and (max-width: 970px) {
  .homepage_hero h1 {
    font-size: 60px;
  }

  .hero_gap {
    padding-top: 0px !important;
    padding-bottom: 20px;
  }

  .Typewriter {
    height: 300px !important;
  }

  #main-content section {
    padding: 30px 32px 100px;
  }
  .homepage #main-content .text_col p {
    width: 80%;
  }

  /*  */
  .middle_section .container_x {
    padding: 70px 32px !important;
  }
  .middle_section h2 {
    font-size: 36px;
  }
  .text_col h2 {
    width: 70%;
    margin: 0 auto;
    font-size: 36px;
  }
  .vision-mission-wrapper {
    padding: 70px 32px;
  }
  .vision-mission-text {
    width: 85%;
    gap: 50px;
    text-align: center;
  }
  .vision-mission-text .top,
  .vision-mission-text .bottom {
    text-align: center;
  }
  .vision-mission-text h3{
    font-size: 32px;
  }
  .vision-mission-text p{
    font-size: 16px;
  }
  .section-img {
    width: 70%;
  }
  #services-section .text_col h2{
    font-size: 36px;
  }
  #services-section .text_col p{
    font-size: 16px;
    width: 100% !important;
  }
  #services-section{
    padding: 70px 32px 50px;
  }

  .homepage #main-content .text_col h2{
    font-size: 36px;
  
  }

  .homepage h2,
  .homepage h3,
  .homepage h4 {
    font-size: 36px;
  }
  .homepage_hero {
  padding: 250px 32px 140px;
  }
  .homepage #main-content {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 717px){
  .homepage_hero h1 {
    font-size: 54px;
  }
}
@media only screen and (max-width: 712px) {
  .homepage #main-content {
    min-height: 1200px;
  }
  .middle_section .img_col .img-wrap {
    max-width: 95%;
  }
  .middle_section .img_col .img-wrap {
    box-shadow: 18px 18px 0px -6px rgba(241, 157, 36, 1);
    -webkit-box-shadow: 18px 18px 0px -6px rgba(241, 157, 36, 1);
    -moz-box-shadow: 18px 18px 0px -6px rgba(241, 157, 36, 1);
  }
  .middle_section .text_col p, .middle_section .last_text {
    font-size: 16px;
  }

  .middle_section h2 {
    margin-left: 0 !important;
  }
  .middle_section .container_x {
    gap: 40px;
  }
}
@media only screen and (max-width: 650px) {
  .homepage_hero {
    height: 70vh;
  }
  .homepage_hero h1 {
    font-size: 48px;
  }
  .Typewriter{
    height: 250px !important;
  }
  .homepage_hero p {
    font-size: 20px;
    width: 100% !important;
    margin: 0 auto;
  }
  .hero_gap {
    /* padding-top: 25px !important; */
    padding-bottom: 20px;
  }

  .homepage #main-content {
    min-height: 980px;
  }
  .homepage #main-content .text_col h2{
    width: 80% !important; 
  }

  /* why choose us */
  #main-content section {
    padding: 30px 15px 70px;
  }
  .homepage #main-content .text_col p {
    width: 96%;
  }
    #services-section{
    padding: 70px 15px 50px;
  }
  .middle_section .container_x{
    padding: 70px 15px 50px !important;
  }
}

@media only screen and (max-width: 617px){
  .homepage_hero h1 {
    font-size: 36px;
  }
  .Typewriter{
    height: 200px !important;
  }
}
@media only screen and (max-width: 560px) {
  .text_col h2 {
    font-size: 24px;
  }
  .homepage #main-content .text_col h2{
    font-size: 24px;
  
  }
  .middle_section h2 {
    font-size: 24px;
    margin-left: 0 !important;
    padding-top: 60px;
  }
  .middle_section .container_x .text_col .btn {
    max-width: 250px !important;
    margin: 0 auto;
    display: block;
  }
  .middle_section .img_col .img-wrap {
    width: 95% !important;
    background: url("/public/images/home/about-img-sm-scr.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

 
  .homepage #main-content {
    padding: 100px 0 20;
  }

  .middle_section {
    min-height: 980px;
    padding: 60px 0;
  }

 
  .vision-mission-wrapper {
    padding: 50px 15px;
  }

  .homepage h2,
  .homepage h3,
  .homepage h4 {
    font-size: 24px;
  }

  .homepage_about h5 {
    font-size: 24px;
  }
  .homepage #main-content {
    min-height: 800px;
  }
  .homepage #main-content {
    padding-top: 100px;
  }
    #services-section .text_col h2{
    font-size: 24px;
  }
}

@media only screen and (max-width: 462px) {
  .homepage_hero h1 {
    font-size: 34px;
  }
  
  .Typewriter {
    height: 170px !important;
  }
  .homepage_hero p {
    font-size: 18px;
    font-weight: 300;
    padding: 0 0 10px !important;
    width: 100 !important;
  }

  .homepage_hero {
    padding: 150px 20px 50px;
    min-height: 70vh !important;
  }

  .testimonial_home h3 {
    font-size: 24px;
  }


}
@media only screen and (max-width: 425px) {
  .homepage_hero h1 {
    font-size: 30px;
  }
  
  .Typewriter {
    height: 170px !important;
  }
  .middle_section {
    padding: 10px 0;
  }
  .homepage_about h5 {
    font-size: 20px;
  }

  .homepage #main-content .text_col p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 390px) {

  .vertical_slide {
    height: 500px;
  }
  .middle_section {
    min-height: 880px;
  }
  .homepage #main-content {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 378px){
  .homepage_hero h1 {
    font-size: 28px;
  }
  .Typewriter {
    height: 150px !important;
  }
}
@media only screen and (max-width: 355px){
  .homepage_hero h1 {
    font-size: 24px;
  }
  .Typewriter {
    height: 130px !important;
  }
  .homepage_hero p{
    font-size: 16px;
  }
  .homepage #main-content .text_col h2{
    width: 95% !important; 
  }
}
.homepage_hero h1 {
  font-size: 50px;
  height: 100%;
}

.homepage_hero p {
  font-size: 20px;
  width: 800px !important;
  font-weight: 400;
  letter-spacing: 0.15;
}
/* home page main (with vertical slider component) styles */
.homepage #main-content {
  background: #060709;
  background: var(--ellipse-gradient);
  padding-top: 150px;
}

.homepage #main-content .text_col h2 {
  font-size: 60px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  width: 50%;
  margin: 0 auto;
  padding-bottom: 24px;
  color: #fff;
  text-align: center;
}
.homepage #main-content .text_col p {
  font-size: 18px;
  font-weight: 500;
  color: var(--light-grey);
  width: 45%;
  margin: 0 auto;
  padding-bottom: 26px;
  text-align: center;
}

#main-content section {
  background: linear-gradient(
      183deg,
      rgba(6, 7, 9, 0.418) -38.62%,
      rgba(6, 7, 9, 0.742) 97.56%
    ),
    center / cover no-repeat url(/public/images/grid_background.png);
  padding: 30px 80px 150px;
}

/* middle section styles */
.middle_section{
  background-color: var(--charcoal-black);
  width: 100%;
}
.middle_section .container_x {
  padding: 90px 0 !important;
  width: 100%;
  min-height: auto;
  background-color: var(--charcoal-black);
}

.middle_section .container_x {
  gap: 50px;
  padding: 70px 90px !important;
}
.middle_section .text_col {
  flex-basis: 55%;
}

.middle_section h2 {
  font-size: 60px;
  color: #fff;
  font-weight: 600;
  margin-bottom: -120px;
  margin-left: 90px;
}
.middle_section .text_col p, .middle_section .last_text {
  font-size: 18px;
  font-weight: 400;
  color: var(--light-grey);
  line-height: 28px;
  margin-bottom: 40px;
}
.middle_section .img_col {
  flex-basis: 45%;
}

.middle_section .img_col .img-wrap {
  width: 424px;
  height: 424px;
  margin: 0 auto;
  background: url("/public/images/home/about-img-lg-scr.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: var(--shadow);
  -webkit-box-shadow: var(--shadow);
  -moz-box-shadow: var(--shadow);
  transition: all 0.5s ease-in-out;
}

.middle_section .img_col .img-wrap:hover {
  box-shadow: none;
}

/* vision-mission section */
.vision-mission-wrapper {
  background: #060709;
  background: var(--ellipse-gradient);
  padding: 90px;
}
.section-img {
  width: 40%;
}
.vision-mission-text {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 84px;
}
.vision-mission-text .top,
.vision-mission-text .bottom {
  text-align: right;
}
.vision-mission-text h3 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
}
.vision-mission-text p {
  font-size: 18px;
  font-weight: 400;
}
/* Services */
#services-section{
  padding: 120px 90px 50px;
}
#services-section .text_col{
  text-align: center;
}
#services-section .text_col h2{
  font-size: 60px;
  font-weight: 600;
}
#services-section .text_col p {
  font-size: 18px;
  font-weight: 400;
  width: 85%;
  margin: 24px auto 0;
}





@media only screen and (max-width: 2480px) {
  .hero_gap {
    padding-top: 90px;
  }
  .inner_padding {
    padding-left: 60px;
    padding-right: 60px;
    font-size: 12px;
  }
  .Typewriter {
    height: 280px;
    width: 920px;
    font-size: 36px;
  }
}

@media only screen and (max-width: 1300px) {
  .hero_gap {
    padding-top: 70px !important;
  }
  .inner_padding {
    padding-left: 60px;
    padding-right: 60px;
    font-size: 12px;
  }
  /* .Typewriter {
    height: 240px;
  } */
  .homepage #main-content .text_col h2 {
    width: 70% !important;
  }
}

@media only screen and (max-width: 1240px) {
  .middle_section h2 {
    margin-left: 50px !important;
  }
  .middle_section .container_x {
    gap: 30px;
    padding: 70px 50px !important;
  }
  .vision-mission-wrapper {
    padding: 70px 50px;
  }
  #services-section{
    padding: 90px 50px 50px;
  }
  #main-content section {
    padding: 30px 50px 150px;
  }
  
}
@media only screen and (max-width: 1170px) {
  .middle_section .img_col .img-wrap {
    width: 400px;
    height: 400px;
  }
  .Typewriter{
    width: 90% !important;
  }
  
}
@media only screen and (max-width: 1100px) {
 
  .hero_gap {
    padding-top: 0px !important;
  }

  .Typewriter {
    height: 350px !important;
    width: 100% !important;
    text-align: center;
  }
  .homepage_hero h1 {
    font-size: 68px;
  }

  .middle_section .text_col {
    width: 70%;
  }
}

@media only screen and (max-width: 1085px) {
  .text_col h2 {
    width: 100%;
  }

  .homepage_hero p {
    font-size: 22px;
    width: 600px !important;
  }
  .middle_section,
  .middle_section .text_col {
    text-align: center;
  }
  .middle_section .flex_wrapper {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .middle_section .img_col {
    margin: 0 auto;
  }

  .middle_section .text_col {
    text-align: center;
    width: 100%;
  }

  .middle_section .text_col p {
    width: 100%;
  }
  .middle_section h2 {
    margin: 0 auto 35px !important;
  }
  .middle_section .img_col {
    flex-basis: 100%;
    width: 100%;
  }
  .middle_section .img_col .img-wrap {
    width: 80% !important;
    height: 312px;
    background: url("/public/images/home/about-img-md-scr.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .section-img {
    width: 40%;
  }
  .vision-mission-text {
    width: 60%;
    gap: 50px;
  }
    .middle_section{
    padding: 80px 0;
  }
  .middle_section .container_x {
    gap: 80px;
  }
  .middle_section h2 {
    /* font-size: 36px; */
    margin-left: 0px !important;
    margin-bottom: 0px !important;
  }
}

/* for tabs */

@media only screen and (max-width: 970px) {
  .homepage_hero h1 {
    font-size: 60px;
  }

  .hero_gap {
    padding-top: 0px !important;
    padding-bottom: 20px;
  }

  .Typewriter {
    height: 300px !important;
  }

  #main-content section {
    padding: 30px 32px 100px;
  }
  .homepage #main-content .text_col p {
    width: 80%;
  }

  /*  */
  .middle_section .container_x {
    padding: 70px 32px !important;
  }
  .middle_section h2 {
    font-size: 36px;
  }
  .text_col h2 {
    width: 70%;
    margin: 0 auto;
    font-size: 36px;
  }
  .vision-mission-wrapper {
    padding: 70px 32px;
  }
  .vision-mission-text {
    width: 85%;
    gap: 50px;
    text-align: center;
  }
  .vision-mission-text .top,
  .vision-mission-text .bottom {
    text-align: center;
  }
  .vision-mission-text h3{
    font-size: 32px;
  }
  .vision-mission-text p{
    font-size: 16px;
  }
  .section-img {
    width: 70%;
  }
  #services-section .text_col h2{
    font-size: 36px;
  }
  #services-section .text_col p{
    font-size: 16px;
    width: 100% !important;
  }
  #services-section{
    padding: 70px 32px 50px;
  }

  .homepage #main-content .text_col h2{
    font-size: 36px;
  
  }

  .homepage h2,
  .homepage h3,
  .homepage h4 {
    font-size: 36px;
  }
  .homepage_hero {
  padding: 250px 32px 140px;
  }
  .homepage #main-content {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 717px){
  .homepage_hero h1 {
    font-size: 54px;
  }
}
@media only screen and (max-width: 712px) {
  .homepage #main-content {
    min-height: 1200px;
  }
  .middle_section .img_col .img-wrap {
    max-width: 95%;
  }
  .middle_section .img_col .img-wrap {
    box-shadow: 18px 18px 0px -6px rgba(241, 157, 36, 1);
    -webkit-box-shadow: 18px 18px 0px -6px rgba(241, 157, 36, 1);
    -moz-box-shadow: 18px 18px 0px -6px rgba(241, 157, 36, 1);
  }
  .middle_section .text_col p, .middle_section .last_text {
    font-size: 16px;
  }

  .middle_section h2 {
    margin-left: 0 !important;
  }
  .middle_section .container_x {
    gap: 40px;
  }
}
@media only screen and (max-width: 650px) {
  .homepage_hero {
    height: 70vh;
  }
  .homepage_hero h1 {
    font-size: 48px;
  }
  .Typewriter{
    height: 250px !important;
  }
  .homepage_hero p {
    font-size: 20px;
    width: 100% !important;
    margin: 0 auto;
  }
  .hero_gap {
    /* padding-top: 25px !important; */
    padding-bottom: 20px;
  }

  .homepage #main-content {
    min-height: 980px;
  }
  .homepage #main-content .text_col h2{
    width: 80% !important; 
  }

  /* why choose us */
  #main-content section {
    padding: 30px 15px 70px;
  }
  .homepage #main-content .text_col p {
    width: 96%;
  }
    #services-section{
    padding: 70px 15px 50px;
  }
}

@media only screen and (max-width: 617px){
  .homepage_hero h1 {
    font-size: 36px;
  }
  .Typewriter{
    height: 200px !important;
  }
}
@media only screen and (max-width: 560px) {
  .text_col h2 {
    font-size: 24px;
  }
  .homepage #main-content .text_col h2{
    font-size: 24px;
  
  }
  .middle_section h2 {
    font-size: 24px;
    margin-left: 0 !important;
    padding-top: 60px;
  }
  .middle_section .container_x .text_col .btn {
    max-width: 250px !important;
    margin: 0 auto;
    display: block;
  }
  .middle_section .img_col .img-wrap {
    width: 95% !important;
    background: url("/public/images/home/about-img-sm-scr.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

 
  .homepage #main-content {
    padding: 100px 0 20;
  }

  .middle_section {
    min-height: 980px;
    padding: 60px 0;
  }

 
  .vision-mission-wrapper {
    padding: 50px 15px;
  }

  .homepage h2,
  .homepage h3,
  .homepage h4 {
    font-size: 24px;
  }

  .homepage_about h5 {
    font-size: 24px;
  }
  .homepage #main-content {
    min-height: 800px;
  }
  .homepage #main-content {
    padding-top: 100px;
  }
    #services-section .text_col h2{
    font-size: 24px;
  }
}

@media only screen and (max-width: 462px) {
  .homepage_hero h1 {
    font-size: 34px;
  }
  
  .Typewriter {
    height: 170px !important;
  }
  .homepage_hero p {
    font-size: 18px;
    font-weight: 300;
    padding: 0 0 10px !important;
    width: 100 !important;
  }

  .homepage_hero {
    padding: 150px 20px 50px;
    min-height: 70vh !important;
  }

  .testimonial_home h3 {
    font-size: 24px;
  }


}
@media only screen and (max-width: 425px) {
  .homepage_hero h1 {
    font-size: 30px;
  }
  
  .Typewriter {
    height: 170px !important;
  }
  .middle_section {
    padding: 10px 0;
  }
  .homepage_about h5 {
    font-size: 20px;
  }

  .homepage #main-content .text_col p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 390px) {

  .vertical_slide {
    height: 500px;
  }
  .middle_section {
    min-height: 880px;
  }
  .homepage #main-content {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 378px){
  .homepage_hero h1 {
    font-size: 28px;
  }
  .Typewriter {
    height: 150px !important;
  }
}
@media only screen and (max-width: 355px){
  .homepage_hero h1 {
    font-size: 24px;
  }
  .Typewriter {
    height: 130px !important;
  }
  .homepage_hero p{
    font-size: 16px;
  }
  .homepage #main-content .text_col h2{
    width: 95% !important; 
  }
}