.internship_page{
    width: 100%;
}
.internship_page header{
    max-width: 100%;
    padding-top: 120px;
    background: #080808;
}
.internship_page header .background_layer{
    height: 344px;
    padding: 0 80px 80px;
    background: url(/public/images/contact_us/contact_us_bg.svg);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    place-content: flex-end;
}
.internship_page header .background_layer h1{
    font-size: 36px;
    color: #fff;
    padding-bottom: 16px;
}
.internship_page header .background_layer p{
    font-size: 18px;
}

.internship_page .flex_wrapper{
    padding: 72px 80px;
    gap: 100px;
}
.internship_page .flex_wrapper p{
    color: #D4D4D4;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: justify;
}
.internship_page .header-txt{
    flex-basis: 35%;
}

.internship_page .header-txt h2{
    font-size: 60px;
    font-weight: 600;
    letter-spacing: 0.3px;
    padding-bottom: 24px;
}
.internship_page .parag-txt{
    flex-basis: 65%;
}
.internship_page .parag-txt p:first-child{
    padding-bottom: 30px;
}
.more_info{
    max-width: 100%;
    background-color: #111;
    padding: 94px 80px 179px !important;
}
.info_cards{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
    flex: 2;
}
.info_card{
    /* max-width: 308px; */
    width: 100%;
    min-height: 348px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(17.5px);
    position: relative;
    padding: 50px 17px 64px;
}
.info_cards .info_card:nth-child(2), 
.info_cards .info_card:last-child{
    transform: translateY(100px);
}

.info_card span{
    margin-bottom: 15px;
    display: block;
}
.info_card h3, .intern_card h2{
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 17px;
}
.info_card li{
    color:#D4D4D4;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    list-style-type: disc;
    margin-left: 25px;
    padding-bottom: 15px;
}
.more_info .image_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

}
.sm-wrap{
    max-width: 282px;
}
.lg-wrap{
    max-width: 440px;
    margin-top: -80px;
}
.sm-wrap img, .lg-wrap img{
    width: 100%;
    height: 100%;
}

.internship_cards_container{
    padding: 100px 80px;
}
.internship_cards_container>h2{
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: center;
}

.internship_cards_container>p{
    padding-bottom: 80px;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
}
.intern_card{
    max-width: 400px;
    min-height: 298px;
    padding: 20px 30px;
    border: 1px solid var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background: rgba(255, 255, 255, 0.10);
    margin: 0 auto;
    
}
.intern_card li{
    color:#D4D4D4;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    list-style-type: circle;
    margin-left: 25px;
    padding-bottom: 20px;
}

.intern_card .btn{
    max-width: 120px;
    min-height: 30px;
    padding: 12px 0px;
    text-align: center;
    margin-top: 20px;

}
/* intern application page */

.single_intern_post{
    margin-top: 100px;
    background: #080808;
    padding: 52px 80px 75px;
    align-items: center;
}
.single_intern_post .image-wrapper{
    width: 838px;
    height: 600px;
}
.single_intern_post .image-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.intern_duties{
    width: 65%;
    min-height: 350px;
    padding: 36px 28px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    background: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(17.5px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: -300px;
}
.intern_duties li{
    font-size: 14px;
    color: #D4D4D4;
    list-style: circle;
    margin-left: 20px;
}
.intern_duties .btn{
    margin-top: 20px;
    width: 200px;
    text-align: center;
}
.intern_duties .disabled{
    cursor:not-allowed;

}
.intern_duties .disabled:hover{
    background:#D3D3D3;
    color: #111;
}
.process{
    padding: 50px 80px;
    background-color:#111;
}
.process h3{
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 15px;
}
.grid-item{
    display: flex;
    align-items: center;
    gap: 20px;
}
.grid-item span{
    background-color: var(--primary);
    padding: 20px 30px;
    text-align: center;
    font-size: 20px;
    border-radius: 4px;
}
@media only screen and (max-width: 1200px){

    .sm-wrap{
        max-width: 200px;
    }
    .lg-wrap{
        max-width: 390px;
    }
    .single_intern_post{
        padding: 52px 50px 75px;
        flex-direction: column;
    }
    .single_intern_post .image-wrapper{
        width: 100%;
        height: 600px;
    }
    .intern_duties{
        margin-left: 0px;
        margin-top: -80px;
        width: 90%;
    }

}
@media only screen and (max-width: 970px){
    .internship_page .header-txt h2{
        font-size: 36px;
    }
    .more_info{
        padding: 70px 50px 150px !important;
        flex-direction: column-reverse;
    }
    .more_info .image_container{
        flex-direction: row;
    }
    .lg-wrap{
        transform: translateY(150px);
    }
    .info_cards .info_card:nth-child(2), 
    .info_cards .info_card:last-child{
        transform: translateY(70px);
    }
    .internship_page .flex_wrapper{
        padding: 50px;
        gap: 70px;
    }

    .info_card{
        max-width:100%;
    }
    .internship_cards_container{
        padding: 100px 50px;
    }
    .intern_card{
        max-width: 480px;
    }
    .intern_duties{
        margin-left: 0px;
        margin-top: -80px;
        width: 90%;
    }
    .single_intern_post{
        padding: 52px 50px 75px;
        flex-direction: column;
    }
    .single_intern_post .image-wrapper{
        width: 100%;
        height: 350px;
        
    }
    .process{
        padding: 50px;
    }

}

@media only screen and (max-width: 680px){
    .more_info{
        padding: 70px 15px !important;
    }
}
@media only screen and (max-width: 560px){
    .internship_page .header-txt h2{
        font-size: 24px;
    }
    .more_info .image_container{
        flex-direction: column;
    }
    .lg-wrap{
        transform: translateY(0px);
    }
    .info_cards{
        grid-template-columns: 1fr;
        grid-gap: 15px;
    }
    .info_cards .info_card:nth-child(2), 
    .info_cards .info_card:last-child{
        transform: translateY(0px);
    }
    .internship_page .flex_wrapper{
        padding: 50px 15px;
        /* gap: 70px; */
    }
    .internship_cards_container{
        padding: 100px 15px;
    }
    .single_intern_post{
        padding: 52px 15px 75px;
    }
    .intern_duties{
        width: 95%;
        padding: 20px 15px;
    }
    .intern_card{
        max-width: 100%;
    }
    .internship_cards_container>h2{
        font-size: 24px;
    }

    .internship_cards_container>p{
        padding-bottom: 0;
        font-size: 16px;
    }
    .process{
        padding: 50px 15px;
    }
}