.testimonialCard-container {
  width: 100%;
  margin-top: 120px;
  padding-bottom: 70px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.testimonialCard-container > div {
  max-width: 637px;
  border-radius: 20px;
  padding: 45px 63px;
  position: relative;
  overflow: hidden;
}

.title-subtitle-card {
  background-color: #060709;
  display: grid;
  place-content: center;
  align-items: flex-start;
}
.title-subtitle-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(6, 7, 9, 0.2));
  pointer-events: none;
}

.title-subtitle-card h5 {
  color: var(--light-grey);
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}
.title-subtitle-card h3 {
  color: #fff;
  font-size: 36px;
  font-weight: 400;
  width: 75%;
}


.testimonial-card {
  border: 0.5px solid #e5e5e55f;
  border-radius: 20px;
  background-color: #0c0d12;
}

.testimonial-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.5),
    rgba(12, 13, 18, 0.2)
  );
  pointer-events: none;
 
}

.testimonial-card .client-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 24px;
}
.testimonial-card .client-wrapper h4 {
  font-size: 24px;
  color: #e5e5e5;
}
.testimonial-card p {
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  line-height: 32px;
}

@media only screen and (max-width: 1164px) {

  .title-subtitle-card h3 {
    font-size: 36px;
    font-weight: 400;
    width: 90%;
  }
}
@media only screen and (max-width: 1100px) {
  .testimonial-card img {
    width: 35px;
    height: 35px;
  }
  .testimonial-card .client-wrapper h4 {
    font-size: 20px;
  }
  .testimonial-card p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1036px){
  .title-subtitle-card h3 {
    font-size: 30px;
    width: 90%;
  }
}
@media only screen and (max-width: 850px) {
  .testimonialCard-container > div {
    padding: 30px;
  }
}
@media only screen and (max-width: 580px) {
  .testimonialCard-container {
    grid-template-columns: 1fr;
  }
  .testimonialCard-container div {
    max-width: 100%;
  }
  .title-subtitle-card{
    height: 280px !important;
  }
  .title-subtitle-card h3{
    width: 75%;
  }
  
}
@media only screen and (max-width: 433px) {
  
  .title-subtitle-card h3{
    width: 90%;
  }
}
@media only screen and (max-width: 372px) { 
  .title-subtitle-card h3{
    width: 100%;
    font-size: 24px;
  }
}
