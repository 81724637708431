.qualities-card-container {
  background: transparent;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 70px;
}
.quality-card {
  padding: 15px;
}
.quality-card h3 {
  font-size: 34px;
  font-weight: 600;
  line-height: 46px;
  width: 80%;
}
.quality-card p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #a3a3a3;
  padding-top: 32px;
  width: 70%;
}
.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ellipse {
  max-width: 64px;
  height: 64px;
}

@media only screen and (max-width: 1400px) {
  .quality-card h3 {
    width: 100%;
    font-size: 28px;
  }
  .quality-card p {
    width: 100%;
  }

}
@media only screen and (max-width: 1200px){
  .quality-card h3 {
    font-size: 24px;
    line-height: 33px;
  }
}

@media only screen and (max-width: 970px) {
    .qualities-card-container {
        row-gap: 50px;
    }
  .quality-card h3 {
    font-size: 19.6px;
    line-height: 24px;
  }
  .quality-card p {
    font-size: 16px;
    padding-top: 24px;
  }
  .ellipse {
    max-width: 30px;
    height: 30px;
  }
}
@media only screen and (max-width: 760px){
    .qualities-card-container {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
    }
    .dashed-line{
        display: none;
    }
}
@media only screen and (max-width: 560px){
    .qualities-card-container {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
    .quality-card{
        display: flex;
        align-items: flex-start;
        gap: 24px;
    }
    .quality-card h3{
        font-size: 20px;
    }
    .quality-card p{
        font-size: 14px;
    }
   
}
