@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700&display=swap");

:root {
  --primary: #7169e1;
  --charcoal-black: #0f0f0f;
  --light-grey: #a3a3a3;
  --shadow: 43px 28px 0px -6px rgba(241, 157, 36, 1);
  --ellipse-gradient: radial-gradient(
    ellipse at 50% 1%,
    #1a1a1c 0%,
    #060709 20%,
    #060709 100%
  );
  --btn-gradient: linear-gradient(177deg, #4169e1 18.75%, #ffa500 231%);
  --transition-speed: all 0.5s ease-in-out;
  --secondary-color: #ffa500;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  width: 100%;
  height: 100%;
  font-family: "Noto Sans", sans-serif;
}

h1 {
  font-weight: 700;
}

h2,
h3,
h4,
h5 {
  font-weight: 600;
}

.flex_wrapper {
  display: flex;
}

.flex_apart {
  justify-content: space-between;
  align-items: center;
}

.flex_center {
  justify-content: center;
  align-items: center;
}

.flex_start {
  align-items: flex-start;
  justify-content: space-between;
}
.flex_end {
  align-items: flex-end;
  justify-content: space-between;
}
.flex_stretch {
  align-items: stretch;
  justify-content: space-between;
}

.grid_wrapper {
  display: grid;
}

.grid_col3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 0.8rem;
}

a {
  text-decoration: none;
}

.btn {
  padding: 10px 15px;
  border: 1px solid white;
  border-radius: 50px;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.btn_lg {
  width: 258px;
  padding: 12px 24px;
  display: inline-block;
  margin: 30px auto;
  text-align: center;
  background-color: #062179;
  border-radius: 50px;
}

.btn:hover {
  transform: scale(1.05);
  background-color: white;
  color: black;
}
.container_x {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 90px;
}
.btn_arr {
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 20px;
}
.rounded_border {
  border-radius: 8px;
}

/* for main services disc */
.process-wrapper {
  min-height: 620px;
}
.process-container .process {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  border: 0.5px solid #a3a3a3;
  background: transparent;
  text-align: center;
  padding: 20px;
  position: relative;
  display: grid;
  place-content: center;
  transition: all 0.5s ease-in-out;
}
.process-container .process:hover{
  box-shadow: 0 3px 10px rgba(241, 156, 36, 0.324);
  border: none;
}
.process-container .process .process-num {
  background: #062179;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 25px;
  display: grid;
  place-content: center;
}
.process-container .process .process-num small {
  font-size: 16px;
}
.process-container .process h2 {
  font-size: 24px;
  font-weight: 400;
}
.process-container .process p {
  font-size: 16px;
  font-weight: 200;
  color: #a3a3a3;
}
.process-container:nth-child(2) {
  transform: translateY(100px);
}

@media only screen and (max-width: 1940px) {
  .container_x {
    padding: 0 50px;
  }
  .process-container .process {
    width: 300px;
    height: 300px;
   
  }
}

@media only screen and (max-width: 1240px) {
  .container_x {
    padding: 0 50px;
  }
  .process-container .process {
    width: 300px;
    height: 300px;
  }
}
@media only screen and (max-width: 1204px) {
  .process-container .process {
    width: 300px;
    left: 3px;
  }
  .process-wrapper {
    min-height: 450px;
  }
}
@media only screen and (max-width: 1057px) {
  .process-container .process {
    width: 230px;
    height: 230px;
    left: 1px;
  }
  .process-container .process .process-num {
    top: 10px;
    right: 10px;
  }
  .process-wrapper {
    min-height: 360px;
  }
}

@media only screen and (max-width: 970px) {
  :root{
    --ellipse-gradient: radial-gradient(
    ellipse at 50% 10%,
    #1a1a1c 0%,
    #060709 40%,
    #060709 80%
    );
  }
  .container_x {
    padding: 0 32px;
  }
  .flex_wrapper {
    flex-direction: column;
  }
}
@media only screen and (max-width: 809px) {
  .process-container .process {
    width: 215px;
    height: 215px;
    left: 3px;
  }
  .process-container .process .process-num {
    top: 10px;
    right: 10px;
  }
  .process-container .process h2 {
    font-size: 20px;
  }
  .process-container .process p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .process-container .process {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    left: 5px;
  }
  .process-container:nth-child(2) {
    transform: translateY(0);
  }
}
@media only screen and (max-width: 560px) {
    :root{
    --ellipse-gradient: radial-gradient(
    ellipse at 50% 10%,
    #1a1a1c 0%,
    #060709 60%,
    #060709 80%
    );
  }
  .container_x {
    padding: 0 15px;
  }
  .btn_arr {
    font-weight: 400;
    font-size: 14px;
  }
}
@media only screen and (max-width: 380px) {
  .process-container .process {
    width: 230px;
    height: 230px;
    left: 1px;
  }
}
