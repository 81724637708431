.main-container {
  width: 100%;
  background-color: #080808;
  color: white;
}

._homepage_hero {
  max-width: 100%;
  min-height: 85vh;
  padding: 150px 60px 140px;
  text-align: center;
  place-content: center;
  background: linear-gradient(
      183deg,
      rgba(65, 105, 225, 0.15) -38.62%,
      rgba(65, 105, 225, 0) 97.56%
    ),
    top / cover no-repeat url(/public/images/ph-hero-bg.webp);
}

._homepage_hero h1 {
  font-size: 80px;

}

._homepage_hero p {
  font-size: 30px;
  width: 800px !important;
  font-weight: 400;
  letter-spacing: 0.15;
  margin: 0 auto;
}

.hero_gap {
  padding-top: 0px;
  width: 70%;
  align-items: center;
  margin: 0 auto;
}

.about-hero-wrapper {
  padding: 100px 86px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-text-col {
  flex-basis: 50%;
  margin-right: auto;
}

.about-img-col {
  flex-basis: 50%;
  display: grid;
  place-content: center;
}

.about-img-col div {
  max-width: 500px;
  height: 500px;
}

.about-img-col div img {
  width: 100%;
  width: 100%;
  display: block;
}

.about-text-col h1 {
  font-size: 72px;
  font-weight: 600px;
  gap: 20px;
}

.about-text-col p {
  font-size: 20px;
  font-weight: 500px;
}

.represent-wrapper {
  /* background-color: #111111; */
  padding: 40px 86px;
  margin-top: 100px;
}

.represent-main {
  display: flex;
  align-items: center;
  width: 100%;
  margin-inline: auto;
}

.flex_wrapper_about {
  display: flex;
  width: 100%;
}

.flex_apart_about {
  justify-content: space-between;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 80px 0;
  box-sizing: border-box;
  background: radial-gradient(circle, #111111 0%, #080808 100%);
}

.represent h1 {
  font-weight: 600;
  font-size: 45px;
}

.represent {
  flex: 1;
  color: #d4d4d4;
  font-size: 12px;
}
.middle_section.about_middle_section{
 min-height: 800px;
 padding: 50px 0 80px;
}
.middle_section.about_middle_section h2{
  margin-bottom: -50px;
}
.middle_section .last_text {
  margin: 0px 90px 0;
}

.middle_section.about_middle_section .text_col p,
.middle_section .last_text {
  line-height: 30px !important;
}

.middle_section.about_middle_section .img_col .img-wrap {
  background: url("/public/images/about_us/aboutpage-lg-scr.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.person {
  flex: 1;
  align-self: stretch;
  position: relative;
}

.person img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}

.core-value-wrapper,
.business-value-wrapper {
  width: 100%;
  padding: 0 90px;
}

.cores-values,
.business-values {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  align-items: stretch;
  gap: 30px;
}

.core h1 {
  font-weight: 400;
  font-size: 60px;
  text-align: center;
}

.core p {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  margin-top: 2rem;
  margin-bottom: 7rem;
  color: #d4d4d4;
}

.business h1 {
  font-weight: 400;
  font-size: 60px;
  margin-top: 4rem;
  text-align: center;
}

.ptb-spacing {
  line-height: 2.6;
}

.business p {
  font-weight: 500;
  font-size: 18px;
  color: #d4d4d4;
  margin-bottom: 70px;
  text-align: center;
}

.expand-on-hover-btn {
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.expand-on-hover-btn:hover {
  transform: scale(1.1);
}

.button-text {
  font-size: 14px;
  font-weight: bold;
  font-family: "Noto Sans", sans-serif;
  transition: color 0.3s ease;
}

.expand-on-hover-btn:hover .button-text {
  color: #ffc107;
}

@media only screen and (max-width: 1240px) {
  .middle_section .last_text {
    margin: 30px 50px 0;
  }
}

@media only screen and (max-width: 1200px) {

  .core-value-wrapper,
  .business-value-wrapper {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 1085px) {
  .middle_section .last_text {
    line-height: 28px !important;
    margin: 0 32px !important;
    margin-top: 0;
  }

  .middle_section.about_middle_section .text_col p {
    line-height: 28px !important;
    margin-bottom: 0 !important;
  }

  .middle_section.about_middle_section .img_col .img-wrap {
    background: url("/public/images/about_us/aboutpage-md-scr.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
}

@media only screen and (max-width: 970px) {
  .about-hero-wrapper {
    padding: 100px 37px 50px;
  }

  .hero_gap {
    padding-top: 30px;
  }

  .flex_wrapper_about {
    flex-direction: column;
  }

  .about-text-col h1 {
    font-size: 60px;
  }

  .about-text-col p {
    font-size: 16px;
  }

  .represent-wrapper {
    padding: 40px 37px;
    margin-top: 120px;
  }

  .represent h1 {
    font-size: 36px;
  }

  .represent-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .core-value-wrapper,
  .business-value-wrapper {
    padding: 0 37px;
  }

  .cores-values,
  .business-values {
    gap: 20px;
  }

  .represent-wrapper {
    padding: 120px 20px 50px 30px;
    margin-top: 100px;
  }

  .person img {
    height: 50%;
    width: 100%;
    object-fit: contain;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
  }

  .core h1,
  .business h1 {
    font-size: 48px;
    margin-top: 60px;
  }

  .core p,
  .business p {
    margin: 20px 0 48px;
  }

  .business-values {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 850px) {
  .about-hero-wrapper {
    padding: 0 37px 0;
  }

  ._homepage_hero h1 {
    font-size: 70px;
    padding-top: 80px;

  }

  ._homepage_hero p {
    font-size: 26px;
    padding-top: 170px;

  }

  .hero_gap {
    padding-top: 30px;
  }

  .about-img-col {
    padding-top: 150px;
  }

  .about-hero {
    height: 100%;
  }

  .cores-values {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 750px) {
  .represent-wrapper {
    margin-top: 65px;
  }

  .hero_gap {
    padding-top: 40px;
  }

  ._homepage_hero h1 {
    font-size: 70px;
    padding-top: 80px;

  }

  ._homepage_hero p {
    font-size: 24px;
    padding-top: 170px;
    padding-left: 180px;
    padding-right: 180px;

  }

  .person {
    height: 450px !important;
    align-self: center;
  }

  .person img {
    height: 100%;
    position: static;
  }
}

@media only screen and (max-width: 633px) {
  .about-hero-wrapper {
    padding: 0 15px 0;
  }

  .business-values,
  .cores-values {
    display: flex;
    flex-direction: column;
  }


  ._homepage_hero h1 {
    font-size: 70px;
    padding-top: 80px;

  }

  ._homepage_hero p {
    font-size: 20px;
    padding-top: 170px;
    padding-left: 200px;
    padding-right: 200px;

  }

  .hero_gap {
    padding-top: 20px;
  }

  .about-img-col {
    padding-top: 170px;
  }

  .about-text-col h1 {
    font-size: 36px;
  }

  .about-hero {
    height: 75vh;
  }

  .represent-wrapper {
    padding: 40px 15px;
  }

  .represent h1 {
    font-size: 30px;
  }

  .core-value-wrapper,
  .business-value-wrapper {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 560px) {
  .middle_section .last_text {
    margin: 0 15px !important;
  }

  .middle_section.about_middle_section .img_col .img-wrap {
    background: url("/public/images/about_us/aboutpage-sm-scr.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  ._homepage_hero h1 {
    font-size: 70px;
    padding-top: 80px;

  }

  ._homepage_hero p {
    font-size: 20px;
    padding-top: 170px;
    padding-left: 200px;
    padding-right: 200px;

  }
}

@media only screen and (max-width: 530px) {
  ._homepage_hero h1 {
    font-size: 60px;
    padding-top: 80px;

  }

  ._homepage_hero p {
    font-size: 16px;
    padding-left: 240px;
    padding-right: 240px;

  }

  .about-hero {
    min-height: 90vh;
  }

  .hero_gap {
    padding-top: 30px;
  }

  .about-hero-wrapper {
    flex-direction: column;
    padding-top: 150px;
  }

  .about-img-col {
    padding-top: 0;
  }

  .represent-main {
    flex-direction: column;
  }

  .core h1,
  .business h1 {
    font-size: 30px;
  }

  .core p,
  .business p {
    font-size: 16px;
  }

  .business-values {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 460px) {

  ._homepage_hero h1 {
    font-size: 50px;
    padding-top: 80px;

  }

  ._homepage_hero p {
    font-size: 16px;
    padding-left: 240px;
    padding-right: 240px;

  }
  ._homepage_hero{
    gap: 20px;
  }

  .about-hero {
    min-height: 90vh;
  }

  .core h1,
  .business h1 {
    font-size: 25px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .core p,
  .core h3 {
    font-size: 16px;
  }

  .business p {
    font-size: 16px;
    padding-top: 0px;
    margin-top: 0px;
  }

  .cores-values,
  .business-values {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 425px) {
  .middle_section.middle_section.about_middle_section {
    padding: 10px 0 50px;
  }

  ._homepage_hero h1 {
    font-size: 50px;
    padding-top: 80px;

  }

  ._homepage_hero p {
    font-size: 16px;
    padding-top: 170px;
    padding-left: 280px;
    padding-right: 280px;

  }
}

@media only screen and (max-width: 360px) {
  ._homepage_hero h1 {
    font-size: 50px;
    padding-top: 80px;

  }

  ._homepage_hero p {
    font-size: 16px;
    padding-top: 170px;
    padding-left: 280px;
    padding-right: 280px;

  }

  .about-hero {
    min-height: 90vh;
  }

  .hero_gap {
    padding-top: 50px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}