.wrapper-div {
  background: linear-gradient(
      183deg,
      rgba(6, 7, 9, 0.781) -38.62%,
      rgba(6, 7, 9, 0.742) 97.56%
    ),
    center / cover no-repeat url(/public/images/grid_background.png);
  gap: 230px;
}

.wrapper-div.loading {
  position: relative;
}

.wrapper-div.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.word-spacing {
  word-spacing: 0;
}

@media (max-width: 640px) {
  .word-spacing {
    word-spacing: 0.5rem;
  }
}

.loading-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.descriptive {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: "noto Sans", sans-serif;
}

#ready {
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  /*line-height: 200px;*/
  letter-spacing: 2px;
  margin-top: 20px;
}

.descriptive h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  /*line-height: normal;*/
  /*letter-spacing: 0.18px;*/
}
.holding {
  margin-left: 2.5rem;
}

.join {
  width: 450px;
  text-align: left;
}
.join p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.09px;
}
.input_field {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 12px 16px;
  /*align-self: stretch;*/
}
.input_styles {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: flex-start;
  margin-bottom: 1rem;
}
.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 1.2px;
}
input {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 0.5px solid #4169e1;
  background: #111;
}
#text_area {
  display: flex;
  padding: 12px 16px 144px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  border: 0.5px solid #4169e1;
  background: #111;
}

.button-div {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.custom-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}

.custom-modal.open {
  display: flex;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modal-content {
  position: relative;
  padding: 20px;
  max-width: 90%;
  width: 100%;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 100px;
  border: 2px solid #fff;
  font-size: 20px;
  padding: 0;
  cursor: pointer;
  background: none;
  color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .wrapper-div {
    gap: 150px;
  }
}
@media (max-width: 1090px) {
  .wrapper-div {
    gap: 50px;
  }
}
@media (max-width: 1024px) {
  .wrapper-div {
    background: linear-gradient(
        183deg,
        rgba(6, 7, 9, 0.781) -38.62%,
        rgba(6, 7, 9, 0.742) 97.56%
      ),
      center / cover no-repeat url(/public/images/grid_background.png);
  }
}
@media (max-width: 600px) {
  .close-button {
    top: 0px;
    right: 10px;
    font-size: 18px;
  }

  .button-div {
    width: 70%;

  }
}
