.desktop_blog_wrapper {
  display: flex;
  gap: 30px;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  scroll-snap-type: proximity;
}
.desktop_blog_wrapper::-webkit-scrollbar {
  display: none;
}
.desktop_blog_wrapper .blog_card {
  min-width: 385px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blog_card {
  max-width: 385px;
  padding: 32px 20px;
  border: 1px solid #525252;
  border-radius: 8px;
  background: #111111;
}
.blog_image {
  width: 100%;
  margin-bottom: 30px;
}
.blog_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog_card h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.blog_card p {
  font-size: 16px;
  font-weight: 200;
  margin-bottom: 24px;
}
.blog_card a {
  padding: 12px 20px;
  border: 1px solid #fff;
  max-width: 200px !important;
  display: block;
  text-align: center;
  border-radius: 30px;
}
@media only screen and (max-width: 970px){
  .tab_blog_grid .blog_card {
    max-width: 100%;
  }
}
@media only screen and (max-width: 834px) {
  .tab_blog_grid .blog_card {
    max-width: 100%;
    padding: 32px 28px;
  }
  .tab_blog_grid .blog_card .blog_image {
    width: 100%;
    height: 385px;
  }

}
@media only screen and (max-width: 560px) {
  .tab_blog_grid{
    background: #080808;
  }
  .tab_blog_grid .blog_card{
    padding: 0px;
    border: none;
    background: transparent;
  }
  .tab_blog_grid.blog_card h2 {
    width: 100%;
  }
  .blog_card p{
    text-align: justify;
    font-size: 16px;
  }
  .blog_card a{
    width: 100%;
    display: block;
    margin:0 auto ;
  }

}
@media only screen and (max-width: 430px){
  .tab_blog_grid .blog_card .blog_image {
    width: 100%;
    height: 270px;
  }
  
}
@media only screen and (max-width: 368px){
  .blog_card h2{
    font-size: 20px;
  }
  .blog_card p{
    font-size: 14px;
  }

}
