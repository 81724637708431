  .linkButton {
    transition: color 0.3s ease;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 200;
    cursor: pointer;
  }

  .header-bg{
    background-color: #080808;
    position: fixed;
    top: 0;
    margin-top: 0;
    padding: 8px;
  }

  footer{
    padding: 50px 80px;
    margin-top: 150px;
  }

  .footer-grid-col{
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  .footer-col h5{
    font-size: 18px;
    margin-bottom: 24px;
  }
  .hideOnDesk{
    display: none;
  }
  .footer-logo img{
    width: 50px;
    height: 50px;
  }
  .footer-col-1{
    width: max-content;
  }
  .footer-col-3 a{
    display: block;
    margin-bottom: 15px;
  }
  .footer-col-4{
    width: 300px;
  }
  .footerLink{
    cursor: pointer;
  }

  .footerLink:hover {
    color: #FFA000 !important;
  }
  .footer-bottom{
    border-top: 0.5px solid #a3a3a36a ;
    text-align: center;
    margin-top: 40px;
    width: 100%;
    padding: 25px 32px 0;
  }
  .linkButtonFooter{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .linkButtonFooter span{
    width: 16px;
    height: 16px;
  }
  .linkButtonFooter span img{
    width: 100%;
    height: 100%;
  }
  
  .linkButtonFooter a{
    color: #ebebeb;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
  }
 
  .linkButtonFooter a:hover,
  .linkButton:hover,
  .linkButton.selected:hover {
    color: #FFA000;
    font-weight: 400;
  }
  


.LinkListContainer {
  background-color: #080808;
  padding: 0.4rem; 
  border-radius: 4px; 
  width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.expand-on-hover-btn {
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease; 
}

.expand-on-hover-btn:hover {
  transform: scale(1.1); 
}

.button-text {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Noto Sans', sans-serif;
  transition: color 0.3s ease;
}



.expand-on-hover-btn:hover .button-text {
  color: #ffc107; 
}

.contact-section {
  padding: 1rem;
}

.contact-section .footerLink {
  font-size: 14px;
}

.contact-section .contact-details {
  margin-top: 1rem;
}

@media (max-width: 1128px) {
  .icon_size{
    width: 16px;
  }
 

}

@media (max-width: 1068px){
  .footer-grid-col{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .footer-col-5{
    width: 400px;
    grid-column: span 4;
    align-items: center;
    margin: 50px auto 0;
    text-align: center;
  }
  .footer-col-5 .icon_wrapper{
    width: 70%;
    margin: 0 auto;
  }
  .footer-col-5 .icon_wrapper a{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-col-4{
    width: 200px;
  }
  .footer-col-2{
    width: 250px;
  }
  .footer-col-3 a{
    font-size: 14px;
  }
  .location_size{
    min-width: 22px !important;
  }

}
@media (max-width: 768px) {
  .hide-button-mobile-view {
    display: none;
  }
  .icon_size{
    width: 18px;
  }
  
  /* .location_size{
    width: 18px;
  } */
  .footer-col-1{
    display: flex;
    gap: 25px;
  }
  .hideOnDesk{
    display: block;
  }
  .footer-bottom{
    display: none;
  }
  .footer-grid-col{
    grid-template-columns: 1fr !important;
  }

  .footer-col-2, .footer-col-4{
    width: 100%;
  }
  .footer-col-5{
    grid-column: span 1;
    margin: 0;
    width: max-content;
    text-align: left;
  }

  .footer-col-5 .icon_wrapper{
    width: 100%;
  }
  .footer-col-5 .icon_wrapper a img{
    width: 14px !important;
  }

}
@media (max-width: 468px) {
  .hide-button-mobile-view {
    display: none;
  }
  .icon_size{
    width: 18px;
  }
  
  .location_size{
    width: 23px;
  }
}


@media (max-width: 368px) {
  .hide-button-mobile-view {
    display: none;
  }
  .icon_size{
    width: 18px;
  }
  
  .location_size{
    width: 28px;
  }
}


.scroll-to-top-btn {
  position: fixed;
  bottom: 40px;
  right: 40px;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top-btn.show {
  opacity: 1;
}
@media only screen and (max-width: 1240px){
  footer{
    padding: 50px 50px 80px;
  }
  
}
@media only screen and (max-width: 970px){
  footer{
    padding: 50px 32px 50px;
  }
  
}
@media only screen and (max-width: 767px){
  .footer-logo-flex{
    display: flex !important;
    gap: 30px;
  }
  .copy-write{
    display: none;
  }
  .service-footer-list{
    min-width: 100% !important;
  
  }

}
@media only screen and (max-width: 560px){
  footer{
    padding: 50px 15px 30px;
  }

}